$(document).ready(function () {
    $.widget("ui.efbAutocomplete", $.ui.autocomplete, {
        options:              {
            minWidth:           300,
            maxWidth:           600,
            actions:            null,
            createAutocomplete: null,
            minLengthHint:      'Type to search',
            noResultsHint:      'No results found',
        },
        _create:              function () {
            this.options.appendTo = this.options.appendTo || this.element.parent();
            let wrapper           = $('<div/>', {class: 'efb-autocomplete-widget formelement'})
            this.actionsContainer = $("<div/>", {class: 'efb-autocomplete-actions'});
            this.actionsContainer.append($("<div/>", {class: 'first-level'}))
            this.actionsContainer.append($("<div/>", {class: 'second-level'}))
            wrapper.append(this.actionsContainer)
            this.options.minWidth = Math.max(this.options.minWidth, this.element.width())
            this.options.maxWidth = Math.max(this.options.minWidth, this.options.maxWidth);
            this.resultUL         = $("<ul>");
            this.input            = $("<input/>", {
                type: 'text',
                css:  {
                    minWidth: this.options.minWidth,
                    maxWidth: this.options.maxWidth,
                }
            }).val(this.element.val());
            wrapper.append(this.input).append(this.resultUL);
            this._initSource();
            this.menu = wrapper
                .appendTo(this._appendTo())
                .menu({role: null, items: "ul > li"})
                .hide()
                .attr({"unselectable": "on"})
                .menu("instance");
            this._bindEvents();
            this.liveRegion = $("<div>", {
                role:            "status",
                "aria-live":     "assertive",
                "aria-relevant": "additions"
            }).appendTo(this.document[0].body);
            this._addClass(this.liveRegion, null, "ui-helper-hidden-accessible");
            this._trigger('createAutocomplete', {}, this)
        },
        _suggest:             function (items) {
            this.resultUL.empty().show();
            this._renderMenu(this.resultUL, items);
            this.showMenu();
            if (this.options.autoFocus) {
                if (!this.menu.active || !this.menu.active.length) {
                    this.menu.active = this.resultUL.find('li').first();
                    this.menu.focus({type: ''}, this.menu.active);
                } else {
                    this.menu.next();
                }
            }
            if (!items.length) {
                this._renderNoResultsHint(this.resultUL, this);
            }
        },
        showMenu:             function () {
            this.isNewMenu = true;
            this.menu.refresh();
            this.menu.element.show();
            let borderW        = 1;
            let actionsHeight  = this.actionsContainer.is(':visible') ? this.actionsContainer.outerHeight() : 0;
            let inputLeftSpace = parseInt(this.input.css('margin-left'));
            let inputTopSpace  = parseInt(this.input.css('margin-top'));
            this.menu.element.css({
                maxWidth: this.input.outerWidth() + inputLeftSpace * 2,
            }).position({
                of:        this.element,
                my:        'left-' + (inputLeftSpace + borderW) + 'px top-' + (inputTopSpace + actionsHeight + borderW) + 'px',
                at:        'left top',
                collision: 'none',
            });
            // // Listen for interactions outside of the widget (#6642)
            this._on(this.document, {
                mousedown: "_closeOnClickOutside"
            });
        },
        search:               function (value, event) {
            value = value != null ? value : this._value();

            // Always save the actual value, not the one passed as an argument
            this.term = this._value();

            if (value.length < this.options.minLength) {
                return this._renderMinLengthHint(this.resultUL, this);
            }
            this.resultUL.find('.efb-autocomplete-hint').remove();
            if (this._trigger("search", event) === false) {
                return;
            }

            return this._search(value);
        },
        _search:              function (value) {
            this.pending++;
            this._addClass(this.input, "ui-autocomplete-loading");
            this.cancelSearch = false;
            this.source({term: value}, this._response());
        },
        _response:            function () {
            let index = ++this.requestIndex;
            return function (content) {
                if (index === this.requestIndex) {
                    this.__response(content);
                }

                this.pending--;
                if (!this.pending) {
                    this._removeClass(this.input, "ui-autocomplete-loading");
                }
            }.bind(this);
        },
        _close:               function (event = {}) {
            if (event !== true && ['menuselect'].indexOf(event.type) === -1 && this.input.is(':focus')) {
                return;
            }
            // Remove the handler that closes the menu on outside clicks
            this._off(this.document, "mousedown");

            if (this.menu.element.is(":visible")) {
                this.menu.element.hide();
                this.menu.blur();
                this.isNewMenu = true;
                this._trigger("close", event);
            }
            const that = this;
            setTimeout(function () {
                that.element.blur();
            }, 20)
        },
        _move:                function (direction, event) {
            if (!this.menu.element.is(":visible")) {
                this.search(null, event);
                return;
            }
            if (!this.menu.active || !this.menu.active.length) {
                let filter       = /^next/.test(direction) ? 'first' : 'last';
                this.menu.active = this.resultUL.find('li')[filter]();
                this.menu.focus({type: 'keydown'}, this.menu.active);
                return;
            }
            this.menu[direction](event);
            // set element in view-port of list
            if (!this.menu.active.length) {
                return;
            }
            let menuTop      = this.resultUL.offset().top,
                activeTop    = this.menu.active.offset().top,
                menuBottom   = menuTop + this.resultUL.outerHeight(),
                activeBottom = activeTop + this.menu.active.outerHeight();
            if (activeBottom > menuBottom) {
                this.resultUL.scrollTop(this.resultUL.scrollTop() + activeBottom - menuBottom)
            } else if (activeTop < menuTop) {
                this.resultUL.scrollTop(this.resultUL.scrollTop() + activeTop - menuTop)
            }
        },
        _bindInput:           function () {
            let suppressInput;
            this._on(this.input, {
                input: function (event) {
                    if (suppressInput) {
                        suppressInput = false;
                        event.preventDefault();
                        return;
                    }
                    this._searchTimeout(event);
                },
                focus: function () {
                    this.selectedItem = null;
                    this.previous     = this._value();
                },
                blur:  function (event) {
                    clearTimeout(this.searching);
                    this.close(event);
                    this._change(event);
                }
            });
        },
        _value:               function () {
            return this.input.val.apply(this.input, arguments);
        },
        __response:           function (content) {
            if (content) {
                content = this._normalize(content);
            }
            this._trigger("response", null, {content: content});
            if (!this.options.disabled && content && !this.cancelSearch) {
                this._suggest(content);
                this._trigger("open");
            }
        },
        _beforeOpen:          function () {

        },
        _onInput:             function () {

        },
        _renderMinLengthHint: function (ul, instance) {
            if (!this.options.minLengthHint) {
                return;
            }
            this._renderHint(this.options.minLengthHint, ul, instance)
        },
        _renderNoResultsHint: function (ul, instance) {
            if (!this.options.noResultsHint) {
                return;
            }
            this._renderHint(this.options.noResultsHint, ul, instance)
        },
        _renderHint(hint, ul) {
            ul.show().empty().append($('<li/>', {class: 'ui-menu-item'}).append($('<div/>', {
                class: 'ui-menu-item-wrapper ui-state-disabled efb-autocomplete-hint'
            }).text(hint)));
        },
        _setOption:           function (key, value) {
            this._super(key, value);
        },
        _bindEvents:          function () {
            let suppressKeyPress, suppressKeyPressRepeat, suppressInput;
            this.menu.collapseAll = function () {};
            this.menu._keydown    = function () {};
            let that              = this;
            this.actionsContainer.on('click.close', 'a.close', function () {
                setTimeout(function () {
                    that.close(true);
                }, 10)
            });
            this.menu._off(this.menu.element, 'mouseleave blur');
            this._off(this.element, 'keydown keypress input focus blur');
            this._on(this.element, {
                focus: function (event) {
                    const that        = this;
                    this.selectedItem = null;
                    this.previous     = this.element.val();
                    this.input.val(this.previous);
                    this._beforeOpen();
                    this.showMenu();
                    this._trigger("open");
                    setTimeout(function () {
                        that.input.select();
                    }, 5);
                    this._bindInput();
                    this.search(this._value(), event);
                }
            });
            this._on(this.menu.element, {
                mousedown:  function (event) {
                    event.preventDefault();
                },
                menufocus:  function (event, ui) {
                    let label, item;
                    // support: Firefox
                    // Prevent accidental activation of menu items in Firefox (#7024 #9118)
                    if (this.isNewMenu) {
                        this.isNewMenu = false;
                        if (event.originalEvent && /^mouse/.test(event.originalEvent.type)) {
                            this.menu.blur();

                            this.document.one("mousemove", function () {
                                $(event.target).trigger(event.originalEvent);
                            });

                            return;
                        }
                    }

                    item = ui.item.data("ui-autocomplete-item");
                    if (!item || item.disabled) {
                        return;
                    }
                    if (false !== this._trigger("focus", event, {item: item})) {

                        // use value to match what will end up in the input, if it was a key event
                        if (event.originalEvent && /^key/.test(event.originalEvent.type)) {
                            this._value(item.value);
                        }
                    }

                    // Announce the value in the liveRegion
                    label = ui.item.attr("aria-label") || item.value;
                    if (label && String.prototype.trim.call(label).length) {
                        this.liveRegion.children().hide();
                        $("<div>").text(label).appendTo(this.liveRegion);
                    }
                },
                menuselect: function (event, ui) {
                    let item     = ui.item.data("ui-autocomplete-item"),
                        previous = this.previous;
                    if (!item) {
                        return event.preventDefault();
                    }
                    // Only trigger when focus was lost (click on menu)
                    if (this.element[0] !== $.ui.safeActiveElement(this.document[0])) {
                        //this.element.trigger("focus");
                        this.previous = previous;

                        // #6109 - IE triggers two focus events and the second
                        // is asynchronous, so we need to reset the previous
                        // term synchronously and asynchronously :-(
                        this._delay(function () {
                            this.previous     = previous;
                            this.selectedItem = item;
                        });
                    }

                    if (false !== this._trigger("select", event, {item: item})) {
                        this._value(item.value);
                        this.element.val(item.value);
                    }

                    // reset the term after the select event
                    // this allows custom select handling to work properly
                    this.term         = this._value();
                    this.selectedItem = item;
                    this.close(event);
                }
            });
            this._on(this.input, {
                keydown:  function (event) {
                    if (this.element.prop("readOnly")) {
                        suppressKeyPress       = true;
                        suppressInput          = true;
                        suppressKeyPressRepeat = true;
                        return;
                    }
                    this.menu.previousFilter = true;

                    suppressKeyPress       = false;
                    suppressInput          = false;
                    suppressKeyPressRepeat = false;
                    let keyCode            = $.ui.keyCode;
                    switch (event.keyCode) {
                        case keyCode.PAGE_UP:
                            suppressKeyPress = true;
                            this._move("previousPage", event);
                            break;
                        case keyCode.PAGE_DOWN:
                            suppressKeyPress = true;
                            this._move("nextPage", event);
                            break;
                        case keyCode.UP:
                            suppressKeyPress = true;
                            this._keyEvent("previous", event);
                            break;
                        case keyCode.DOWN:
                            suppressKeyPress = true;
                            this._keyEvent("next", event);
                            break;
                        case keyCode.ENTER:
                            if (this.menu.active) {
                                suppressKeyPress = true;
                                event.preventDefault();
                                this.menu.select(event);
                            }
                            break;
                        case keyCode.TAB:
                            if (this.menu.active) {
                                this.menu.select(event);
                            }
                            break;
                        case keyCode.ESCAPE:
                            this._value('');
                            this.menu.active = null;
                            this.menu.refresh();
                            this.resultUL.find('.ui-state-active').removeClass('ui-state-active')
                            event.preventDefault();
                            break;
                        default:
                            suppressKeyPressRepeat = true;

                            // search timeout should be triggered before the input value is changed
                            this._searchTimeout(event);
                            break;
                    }
                    const that = this;
                    setTimeout(function () {
                        delete that.menu.previousFilter;
                    }, 20);
                },
                keypress: function (event) {
                    if (suppressKeyPress) {
                        suppressKeyPress = false;
                        if (!this.isMultiLine || this.menu.element.is(":visible")) {
                            event.preventDefault();
                        }
                        return;
                    }
                    if (suppressKeyPressRepeat) {
                        return;
                    }
                    this.menu.previousFilter = true;

                    // Replicate some key handlers to allow them to repeat in Firefox and Opera
                    let keyCode = $.ui.keyCode;
                    switch (event.keyCode) {
                        case keyCode.PAGE_UP:
                            this._move("previousPage", event);
                            break;
                        case keyCode.PAGE_DOWN:
                            this._move("nextPage", event);
                            break;
                        case keyCode.UP:
                            this._keyEvent("previous", event);
                            break;
                        case keyCode.DOWN:
                            this._keyEvent("next", event);
                            break;
                    }
                    const that = this
                    setTimeout(function () {
                        delete that.menu.previousFilter;
                    }, 20);
                },
                input:    function (event) {
                    this._onInput();
                    if (suppressInput) {
                        suppressInput = false;
                        event.preventDefault();
                        return;
                    }
                    this._searchTimeout(event);
                },
                focus:    function () {
                    this.selectedItem = null;
                    this.previous     = this._value();
                },
                blur:     function (event) {
                    clearTimeout(this.searching);
                    const that = this
                    setTimeout(function () {
                        that.close(event);
                        that._change(event);
                    }, 20);
                }
            });
        },

    });
});